import React from 'react'
import {ReactComponent as ChevronIcon} from 'images/icons/Chevron.svg'
import {Link} from 'components/link'
import Style from './databaseSubPages.module.css'


export const DatabaseSubPages = ({pages}) => (
  <div className={Style.container}>
    {pages.map(({button}, idx) => (
      <Link className={Style.page} to={button.url} target={button.target} key={idx}>
        <h2 className={Style.title}>{button.title}</h2>
        <ChevronIcon className={Style.icon}/>
      </Link>
    ))}
  </div>
)
