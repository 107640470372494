import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout'
import PageBanner from '../components/page-banner'
import {MainBanner} from 'components/databases/banners/mainBanner'
import {DatabaseSubPages} from 'components/databases/databaseSubPages.js'




export const DatabaseMainTemplate = ({data}) => (
  <Layout
    articleId={`${data.wordpressPage.type}-${data.wordpressPage.wordpress_id}`}
    articleClassName={`${data.wordpressPage.classes}`}>
    <PageBanner props={data.wordpressPage.pageBanner}/>
    <div className="entry-content" style={{maxWidth: 640, margin: 'auto', padding: 30}} dangerouslySetInnerHTML={{__html:data.wordpressPage.content}}/>
    <DatabaseSubPages pages={data.wordpressPage.buttonsDatabase || []}/>
  </Layout>
)

export default DatabaseMainTemplate
export const pageDatabaseQuery = graphql `
query DatabaseMainPageById($id: String!){
  wordpressPage(id:{eq:$id}){
    title
    content
    pageBanner {
     ...PageBanner
     
    }
    buttonsDatabase {
      button {
        title
        url
        target
      }
    }
    path
    type
    date
    wordpress_id
    classes
    featured_media {
      localFile {
        ...PageBannerImages
      }
    }
  }

}
`
